var Picamobile = (function (p, $) {

    ///
    /// PRIVATE
    ///

    var site = p || {},
        psUrl = '/',
        token = {},
        siteShareVisible = 1,
        navOpened = false;

    ///
    /// PUBLIC
    ///
    site.init = function () {
        $(document).ajaxComplete(function (event, request, settings) {
            // ha a session lejár reload mezőt tartalmazni fog a response, az oldalt frissíteni kell
            if (typeof request.responseJSON.reload !== 'undefined') {
                window.location.reload();
            }
        });

        $('.mailto').each(function () {
            var email = $(this).data('email');
            var rev = email.replace(/[a-zA-Z]/g, function (c) {
                return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
            });
            $(this).attr('href', 'mailto:' + rev);
        });

        return site;
    };

    /**
     * Weboldal psUrl
     *
     * @param p_url
     * @return site
     */
    site.setUrl = function (p_url) {
        psUrl = p_url;

        return site;
    };

    site.getUrl = function () {
        return psUrl;
    };

    site.getToken = function () {
        return token;
    };

    site.setToken = function (p_token) {
        token = p_token;

        return site;
    };

    site.showNav = function () {
        var nav = $('nav').find('ul');
        if (navOpened) {
            nav.fadeOut();
            $('.nav-bg').hide();
            $('.nav-footer').hide();
            navOpened = false;
        } else {
            nav.fadeIn(500, site.setNavOpened);
            $('.nav-bg').fadeIn(2000);
            $('.nav-footer').delay(2000).fadeIn(500);
        }
    };

    site.setNavOpened = function () {
        navOpened = true;
    };

    site.chooseTime = function (type) {
        if ((type != 'hour' && !Order.getIsHourDone()) || (type == 'second' && !Order.getIsMinuteDone())) {
            return false;
        }

        var left = $('.left-side');

        $('.tinfo').removeClass('active');
        $('.' + type + '-info').addClass('active');

        left.find('.chooser').css('bottom', '-310px');
        left.find('.' + type + '.chooser').css('bottom', '-10px');
    };

    site.toggleSiteShare = function () {
        $('.mobile-site-sharing-tool').toggle();
        var shareSite = $('.sharesite');
        if (siteShareVisible == 1) {
            siteShareVisible = 0;
            shareSite.addClass('active');
        } else {
            siteShareVisible = 1;
            shareSite.removeClass('active');
        }
    };

    site.to = function (url) {
        window.location.href = psUrl + '/' + url;
    };

    site.navigate = function (url) {
        window.location.href = url;
    }

    site.cookieLawClose = function () {
        $('.cookielaw').slideUp();
        $.ajax({
            type: 'POST',
            url: site.getUrl() + '/setCookielaw',
            headers: site.getToken()
        });
    };

    return site;

}(Picamobile || {}, jQuery));