!function ($) {
    'use strict';

    window.fbAsyncInit = function () {
        FB.init({
            appId: '1641082829478816',
            xfbml: true,
            version: 'v2.5'
        });
    };

    function getTimeNum(t) {
        return (parseInt(t.h) * 60 * 60) + (parseInt(t.m) * 60) + parseInt(t.s);
    }

    window.Sharing = {
        /**
         * A facebook képmegosztó cuccos
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param {number} mode
         * @param e
         */
        shareFb: function (sec, mode, e) {
            e.preventDefault();

            if (sec !== false) {
                var s = Slideshow.parseTime(sec);
            }
            if (mode == 1) {
                // képmegosztás
                window.open('https://www.facebook.com/sharer.php?u=' +
                    encodeURIComponent(Picamobile.getUrl() + '/?time=' + s.hour + ':' + s.minute + ':' + s.second)
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else if (mode == 2) {
                // kezdőlap megosztás
                window.open('https://www.facebook.com/sharer.php?u=' +
                    encodeURIComponent(Picamobile.getUrl())
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else {
                // top100 oldal megosztás
                window.open('https://www.facebook.com/sharer.php?u=' +
                    encodeURIComponent(Picamobile.getUrl() + '/top-100')
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
        },
        /**
         * Twitter képemgosztás
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param {number} mode
         * @param e
         */
        shareTwitter: function (sec, mode, e) {
            e.preventDefault();

            if (sec !== false) {
                var s = Slideshow.parseTime(sec);
            }
            if (mode == 1) {
                // képmegosztás
                window.open('https://twitter.com/intent/tweet?text='
                    + encodeURIComponent(s.hour + ':' + s.minute + ':' + s.second + ' – Your Space in Time - Picture your Second on PicaSecond.com')
                    + '&via=Picamobile&url='
                    + encodeURIComponent(Picamobile.getUrl() + '/?time=' + s.hour + ':' + s.minute + ':' + s.second),
                    '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else if (mode == 2) {
                // kezdőlap megosztás
                window.open('https://twitter.com/intent/tweet?text='
                    + encodeURIComponent('Pic a Second - Your Space in Time - First website to sell time in pictures!')
                    + '&via=PicaSecond&url='
                    + encodeURIComponent(Picamobile.getUrl() + '/'),
                    '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else {
                // top100 oldal megosztás
                window.open('https://twitter.com/intent/tweet?text='
                    + encodeURIComponent('Top100 of Pic a Second. First website to sell time. See the best seconds & pictures!')
                    + '&via=Picamobile&url='
                    + encodeURIComponent(Picamobile.getUrl() + '/top-100'),
                    '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
        },
        /**
         * Google+ képmegosztás
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param {number} mode
         * @param e
         */
        shareGooglePlus: function (sec, mode, e) {
            e.preventDefault();

            if (sec !== false) {
                var s = Slideshow.parseTime(sec);
            }
            if (mode == 1) {
                // képmegosztás
                window.open('https://plus.google.com/share?url=' + encodeURIComponent(Picamobile.getUrl() + '?ty=gp&time=' + s.hour + ':' + s.minute + ':' + s.second)
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else if (mode == 2) {
                // kezdőlap megosztás
                window.open('https://plus.google.com/share?url=' + encodeURIComponent(Picamobile.getUrl())
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else {
                // top100 oldal megosztás
                window.open('https://plus.google.com/share?url=' + encodeURIComponent(Picamobile.getUrl() + '/top-100')
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
        },
        /**
         * Pinterest képmegosztás
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param e
         */
        picSharePinterest: function (sec, e) {
            e.preventDefault();

            var s = Slideshow.parseTime(sec);
            window.open(
                'http://pinterest.com/pin/create/button/?url=' + encodeURIComponent(Picamobile.getUrl()) + '&media=' +
                encodeURIComponent(Picamobile.getUrl() + '/sharePicture/' + getTimeNum(sec)) + '&description=' +
                encodeURI(s.hour + ':' + s.minute + ':' + s.second + ' – Your Space in Time. Picture your Second on PicaSecond.com')
                , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        },
        /**
         * Tumblr képmegosztás
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param e
         */
        picShareTumblr: function (sec, e) {
            e.preventDefault();

            var s = Slideshow.parseTime(sec);

            window.open(
                'http://www.tumblr.com/share/photo?source=' + encodeURIComponent(Picamobile.getUrl() + '/sharePicture/' + getTimeNum(sec)) +
                '&caption=' + encodeURI(s.hour + ':' + s.minute + ':' + s.second + ' – Your Space in Time. Picture your Second on PicaSecond.com') +
                '&click_thru=' + encodeURIComponent(Picamobile.getUrl() + '?time=' + s.hour + ':' + s.minute + ':' + s.second)
                , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');

        },
        /**
         * Reddit képmegosztás
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param e
         */
        picShareReddit: function (sec, e) {
            e.preventDefault();

            var s = Slideshow.parseTime(sec);
            window.open(
                'http://www.reddit.com/submit?url=' + encodeURIComponent(Picamobile.getUrl() + '/sharePicture/' + getTimeNum(sec)) + '&title=' +
                encodeURIComponent(s.hour + ':' + s.minute + ':' + s.second + ' – Your Space in Time - Picture your Second on PicaSecond.com')
                , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        },
        /**
         * Stumbleupon megosztás
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param {number} mode
         * @param e
         */
        shareStumbleUpon: function (sec, mode, e) {
            e.preventDefault();

            if (sec !== false) {
                var s = Slideshow.parseTime(sec);
            }
            if (mode == 1) {
                // képmegosztás
                window.open(
                    'http://www.stumbleupon.com/likecontent?url=' + encodeURIComponent(Picamobile.getUrl() + '&time=' + s.hour + ':' + s.minute + ':' + s.second)
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else if (mode == 2) {
                // kezdőlap megosztás
                window.open(
                    'http://www.stumbleupon.com/submit?url=' + encodeURIComponent(Picamobile.getUrl()) + '&title=' +
                    encodeURIComponent('Pic a Second - Your Space in Time. First website to sell time in pictures!')
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            } else {
                // top100 oldal megosztás
                window.open(
                    'http://www.stumbleupon.com/submit?url=' + encodeURIComponent(Picamobile.getUrl()) + '&title=' +
                    encodeURIComponent('Top100 of Pic a Second. First website to sell time. See the best seconds & pictures!')
                    , '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
            }
        },
        /**
         * Standard email
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param e
         * @param standard
         */
        openMail: function (sec, e, standard) {
            e.preventDefault();

            if (sec !== false) {
                var s = Slideshow.parseTime(sec);
                window.location.href = 'mailto:?subject=' + encodeURIComponent(s.hour + ':' + s.minute + ':' + s.second + ' – Your Space in Time') +
                    '&body=' + encodeURIComponent('Picture your Second on') + '%0D' +
                    encodeURIComponent('http://www.picasecond.com/?time=' + s.hour + ':' + s.minute + ':' + s.second);
            } else {
                if (standard) {
                    window.location.href = 'mailto:?subject=' + encodeURIComponent('Pic a Second - Your Space in Time') +
                        '&body=' + encodeURIComponent('First website to sell time in pictures!') + '%0D' +
                        encodeURIComponent('http://www.picasecond.com');
                } else {
                    window.location.href = 'mailto:?subject=' + encodeURIComponent('Top100 of Pic a Second') +
                        '&body=' + encodeURIComponent('First website to sell time. See the best seconds & pictures!') + '%0D' +
                        encodeURIComponent('http://www.picasecond.com/top100');
                }
            }
        },
        /**
         * Gmail
         *
         * @param e
         * @param standard
         */
        openGmail: function (e, standard) {
            e.preventDefault();

            if (standard) {
                window.location.href = 'https://mail.google.com/mail/?view=cm&fs=1&su=' + encodeURIComponent('Pic a Second - Your Space in Time') +
                    '&body=' + encodeURIComponent('First website to sell time in pictures!')  + '%0D' + encodeURIComponent('http://www.picasecond.com');
            } else {
                window.location.href = 'https://mail.google.com/mail/?view=cm&fs=1&su=' + encodeURIComponent('Top100 of Pic a Second')
                    + '&ampbody=' + encodeURIComponent('First website to sell time. See the best seconds & pictures!')  + '%0D' +
                    encodeURIComponent('http://www.picasecond.com');
            }
        },
        /**
         * Whatsapp képmegosztás (csak mobil)
         *
         * @param {{h: number, m: number, s: number}} sec
         * @param elem
         */
        shareWhatsapp: function (sec, elem) {
            var s = Slideshow.parseTime(sec);
            $(elem).attr('href', 'whatsapp://send?text=' + encodeURIComponent(s.hour + ':' + s.minute + ':' + s.second + ' – Your Space in Time. Picture your Second on http://www.PicaSecond.com'));
        },
        /**
         * Whatsapp oldalmegosztás (csak mobil)
         */
        shareWhatsappSite: function (elem, standard) {
            $(elem).attr('href', 'whatsapp://send?text=' + encodeURIComponent(standard ? 'Pic a Second - Your Space in Time. First website to sell time in pictures! http://www.PicaSecond.com' : 'Top100 of Pic a Second. First website to sell time. See the best seconds & pictures! http://www.picasecond.com/top100'));
        }
    };

}(window.jQuery);